import React, { useEffect, useState } from "react";
import { useI18n } from "@jarvis/react-portal-addons";
import { getDateAndTime } from "../../utils/commonMethods";
import { reportType } from "../../static/consts";
import ReportAccoridanAssessment from "../ReportAccordionAssessment";
import ReportAccordianRemediation from "../ReportAccordianRemediation";

import {
  ReportHeader,
  ReportHeaderText,
  LastSynced,
  ReportSubHeader,
  ReportRiskLabel,
  ReportRiskColorIndicator,
  Consolidated,
  AssessConsolidated
} from "./style";
import { Accordion } from "@veneer/core";

export const Report = props => {
  const [accordionList, setAccordionList] = useState([]);

  const { t } = useI18n();
  const selectReportTime = props.navigation.location.state
    ? props.navigation.location.state
    : "";
  const assessReport = {
    content: (
      <>
        <AssessConsolidated data-testid="device-report-text">
          {t(
            `ecp-endpointsecurity-devicespage.report.innerSubtitle.${props.reportType}`,
            ""
          )}
        </AssessConsolidated>
        {/* <LastRunLabel>
          {t(`ecp-endpointsecurity-devicespage.report.innerSubtitle.lastDateRun`, "Last date run")}
          <LastRunDate>
            {getDateAndTimeFormat2(
              props.reportSectionData.assessmentStatus.lastAssessed
            )}
          </LastRunDate>
        </LastRunLabel> */}
        <ReportSubHeader>
          <div></div>
          <ReportRiskLabel>
            <div data-testid="device-report-passed">
              <ReportRiskColorIndicator backgroundColor="#57AEA4"></ReportRiskColorIndicator>
              {t(
                `ecp-endpointsecurity-devicespage.common.risks.passed`,
                "Passed"
              )}
            </div>
            <div data-testid="device-report-lowrisk">
              <ReportRiskColorIndicator backgroundColor="#FFCE33"></ReportRiskColorIndicator>
              {t(
                `ecp-endpointsecurity-devicespage.common.risks.lowRisk`,
                "Low Risk"
              )}
            </div>
            <div data-testid="device-report-medrisk">
              <ReportRiskColorIndicator backgroundColor="#F35D3E"></ReportRiskColorIndicator>
              {t(
                `ecp-endpointsecurity-devicespage.common.risks.mediumRisk`,
                "Medium Risk"
              )}
            </div>
            <div data-testid="device-report-highrisk">
              <ReportRiskColorIndicator backgroundColor="#D41E4F"></ReportRiskColorIndicator>
              {t(
                `ecp-endpointsecurity-devicespage.common.risks.highRisk`,
                "High Risk"
              )}
            </div>
          </ReportRiskLabel>
        </ReportSubHeader>
        <div className="DD_reportAccordion">
          {props.reportType === reportType.assessment ? (
            <ReportAccoridanAssessment
              isWex={props.isWex}
              assessmentDetails={props.reportSectionData.settingsAssessmentList.settingsAssessment.filter(
                i => Object.keys(i)[0].includes(reportType.assessment)
              )}
              selectReportTime={selectReportTime}
              locale={props?.locale}
            />
          ) : (
            <ReportAccordianRemediation
              isWex={props.isWex}
              remdiationDetails={props.reportSectionData.settingsAssessmentList.settingsAssessment.filter(
                i => Object.keys(i)[0].includes(reportType.remediation)
              )}
              selectReportTime={selectReportTime}
              locale={props?.locale}
            />
          )}
        </div>
      </>
    ),
    expanded: true,
    header: {
      centralArea: (
        <span>
          {t(
            `ecp-endpointsecurity-devicespage.reportSelection.accordionHeader.${props.reportType}`,
            ""
          )}
        </span>
      )
    },
    id: "topReport"
  };
  const handleExpand = (event, index, item) => {
    const updatedData = [...accordionList];
    updatedData[index].expanded = true;
    setAccordionList(updatedData);
  };

  const handleCollapse = (event, index, item) => {
    const updatedData = [...accordionList];
    updatedData[index].expanded = false;
    setAccordionList(updatedData);
  };
  useEffect(() => {
    setAccordionList([assessReport]);
  }, [props.reportType]);

  return (
    <div data-testid="device-report-area">
      <ReportHeader data-testid="device-report-header">
        <ReportHeaderText data-testid="device-report-type">
          {t(
            `ecp-endpointsecurity-devicespage.reportSelection.reportType.${props.reportType}`,
            ""
          )}
        </ReportHeaderText>
        <Consolidated data-testid="device-report-text">
          {t(
            `ecp-endpointsecurity-devicespage.report.subtitle.default`,
            "View the most recent reports of security policies. See additional reports in the Reports menu."
          )}
        </Consolidated>
        <LastSynced>{getDateAndTime(undefined, props?.locale)}</LastSynced>
      </ReportHeader>
      <Accordion
        data-testid="device-report-accordian"
        gutter={16}
        items={accordionList}
        onExpand={handleExpand}
        onCollapse={handleCollapse}
        id="DetailsReportsAccordion"
      />

    </div>
  );
};
