import React, { useState, useEffect } from 'react';
import {
  PDFHTMLHolder,
  ReportSelector,
  ReportSelectorContainer,
  // HorizontalLine,
  ReportContainer,
  LoaderContainer,
  // ReportDetailTitle,
  Pagination,
  Disclaimer,
  CancelButton,
  DownloadButton,
  ExportTypeSelect,
  Footer,
  Subtitle,
  Title
} from './style';

import { savePDF } from '@progress/kendo-react-pdf';
import { Button } from '@veneer/core';
import { Select } from '@veneer/core';
import { Modal } from '@veneer/core';
import { ProgressIndicator } from '@veneer/core';

import { reportType } from '../../static/consts';
import ReportDetailsHTML from '../PdfTemplate/ReportDetailsHTML';
import { useI18n } from '@jarvis/react-portal-addons';
import { Report } from '../Report';
import { IconWarningAlt, ThemeProvider } from '@veneer/core';
import { DeviceNotExist, WarningIcon, WarningLabel } from '../DeviceDetailsCard/style';
import { getSystem } from '../../shared-components/WEXTheme';
import { SemanticsExtended } from '@veneer/semantics';
import { LegalDisclaimer } from '../LegalDisclamer';

export const DetailsSelection = (props) => {
  const [html, setHtml] = useState(<></>);
  const pdfExportComponent = React.useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [fileFormat, setFileFormat] = useState<any>([1]);
  const [modalTitle, setModalTitle] = useState<any>([1]);
  const [selectedChoice, setSelectedChoice] = useState<any>([]);
  const { t } = useI18n();
  const { EXPORT_REPORT_ACCESS, isWex } = props;

  function checkPropertiesNull(obj) {
    return (
      (obj && obj?.settingsAssessmentList?.settingsAssessment?.length === 0) || obj?.length === 0
    );
  }

  const onExport = () => {
    const days = props.totalDuration[0] == 1 ? 1 : props.totalDuration[0] == 2 ? 7 : 30;
    setHtml(
      ReportDetailsHTML(
        selectedChoice[0],
        props.reportSectionData,
        props.deviceName,
        days,
        props?.locale,
        t
      )
    );
    setTimeout(() => {
      const element = pdfExportComponent.current || document.body;
      savePDF(
        element,
        {
          paperSize: 'Letter',
          fileName: modalTitle,
          pageTemplate: pageTemplate
        },
        () => setHtml(<></>)
      );
    }, 2000);
  };

  const pageTemplate = (props) => {
    return (
      <>
        <Pagination data-testid="pagenumber-report">
          {`${t('ecp-endpointsecurity-devicespage.report.pagnation.page', 'Page')} ${
            props.pageNum
          } ${t('ecp-endpointsecurity-devicespage.report.pagnation.of', 'of')} ${props.totalPages}`}
        </Pagination>

        <Disclaimer data-testid="disclaimer-report">
          <p>
            {t(
              'ecp-endpointsecurity-devicespage.report.disclaimer',
              "This report is provided for general comparison only. The information contained is based on manufacturer's published and internal specifications, and proprietary data and algorithms. The information is not guaranteed accurate by HP Development Company. Users can customize the security policies used in the analysis, which will affect the results. Actual results may vary."
            )}
          </p>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              height="6px"
              width="6px"
              version="1.1"
              id="Capa_1"
              viewBox="0 0 276.715 276.715"
            >
              <g>
                <path d="M138.357,0C62.066,0,0,62.066,0,138.357s62.066,138.357,138.357,138.357s138.357-62.066,138.357-138.357   S214.648,0,138.357,0z M138.357,258.715C71.992,258.715,18,204.723,18,138.357S71.992,18,138.357,18   s120.357,53.992,120.357,120.357S204.723,258.715,138.357,258.715z" />
                <path d="M194.798,160.903c-4.188-2.677-9.753-1.454-12.432,2.732c-8.694,13.593-23.503,21.708-39.614,21.708   c-25.908,0-46.985-21.078-46.985-46.986s21.077-46.986,46.985-46.986c15.633,0,30.2,7.747,38.968,20.723   c2.782,4.117,8.375,5.201,12.496,2.418c4.118-2.782,5.201-8.377,2.418-12.496c-12.118-17.937-32.262-28.645-53.882-28.645   c-35.833,0-64.985,29.152-64.985,64.986s29.152,64.986,64.985,64.986c22.281,0,42.759-11.218,54.778-30.009   C200.208,169.147,198.985,163.582,194.798,160.903z" />
              </g>
            </svg>{' '}
            {t(
              'ecp-endpointsecurity-devicespage.report.pdfCopyright',
              `Copyright ${new Date().getFullYear()} HP Development Company, L.P.`
            )}
          </p>
        </Disclaimer>
      </>
    );
  };

  useEffect(() => {
    const selectReportType = props?.navigation?.location?.pathname?.split('/')[5]
      ? props.navigation.location.pathname.split('/')[5]
      : window.location.pathname.split('/')[7];
    selectReportType && setSelectedChoice([selectReportType.toString()]);
  }, []);

  return (
    <div>
      <PDFHTMLHolder>
        <div ref={pdfExportComponent}>{html}</div>
      </PDFHTMLHolder>
      {/* {!isWex && (
        <ReportDetailTitle>
          <span>
            {t(`ecp-endpointsecurity-devicespage.reportSelection.title`, 'Detail Report')}
          </span>
        </ReportDetailTitle>
      )}
      {!isWex && <HorizontalLine />} */}
      <ThemeProvider density="high">
        <ReportSelectorContainer data-testid="ReportSelectorContainer">
          <ReportSelector
            disabled={props.reportSectionLoading}
            data-testid="device-report-select"
            clearIcon={false}
            value={selectedChoice}
            options={
              !checkPropertiesNull(props.reportSectionData)
                ? [
                    {
                      value: reportType.assessment,
                      label: t(
                        `ecp-endpointsecurity-devicespage.reportSelection.reportType.assessment`,
                        'Device Assessment Details'
                      )
                    },
                    {
                      value: reportType.remediation,
                      label: t(
                        `ecp-endpointsecurity-devicespage.reportSelection.reportType.remediation`,
                        'Device Remediation Details'
                      )
                    }
                  ]
                : [
                    {
                      value: 'report-na',
                      label: t(
                        `ecp-endpointsecurity-devicespage.reportSelection.reportType.noReport`,
                        'No available report.'
                      ),
                      disabled: true
                    }
                  ]
            }
            id={'Report_Select'}
            placeholder={t(
              `ecp-endpointsecurity-devicespage.reportSelection.placeholder`,
              'Select a Report to View'
            )}
            onChange={({ value: v }) => {
              setSelectedChoice([v]);
              setModalTitle(
                t(`ecp-endpointsecurity-devicespage.reportSelection.reportType.${v}`, '')
              );
            }}
          />
          <ThemeProvider
            density="high"
            customSemantics={isWex ? getSystem() : ('default' as unknown as SemanticsExtended)}
            shape={isWex ? 'pill' : 'round'}
          >
            {EXPORT_REPORT_ACCESS && (
              <Button
                data-testid="device-report-export"
                customStyle={{ marginRight: '12px', minWidth: '132px' }}
                appearance="secondary"
                onClick={() => onExport()}
                disabled={
                  selectedChoice.length === 0 ||
                  checkPropertiesNull(props.reportSectionData) ||
                  props.reportSectionLoading
                }
              >
                <span>
                  {t(
                    `ecp-endpointsecurity-devicespage.reportSelection.exportAsPDF`,
                    'Export as PDF'
                  )}
                </span>
              </Button>
            )}
          </ThemeProvider>
        </ReportSelectorContainer>
      </ThemeProvider>

      {props.reportSectionLoading ? (
        <ReportContainer>
          <LoaderContainer>
            <ProgressIndicator />
            <span>
              {t(
                `ecp-endpointsecurity-devicespage.report.placeholder.loadingReport`,
                'Loading report...'
              )}
            </span>
          </LoaderContainer>
        </ReportContainer>
      ) : (
        <>
          {selectedChoice.length == 0 ? (
            <ReportContainer>
              <DeviceNotExist>
                <WarningIcon data-testid="warningIcon">
                  <IconWarningAlt size={24} />
                </WarningIcon>

                <WarningLabel data-testid="warningLabel">
                  {t(
                    `ecp-endpointsecurity-devicespage.report.placeholder.noReportSelected`,
                    'No report has been selected'
                  )}
                </WarningLabel>
              </DeviceNotExist>
            </ReportContainer>
          ) : (
            <>
              {checkPropertiesNull(props.reportSectionData) ? (
                <ReportContainer>
                  {t(`ecp-endpointsecurity-devicespage.report.placeholder.noData`, 'No Data')}
                </ReportContainer>
              ) : (
                <Report reportType={selectedChoice[0]} {...props} isWex={isWex} />
              )}
            </>
          )}
        </>
      )}

      <LegalDisclaimer />
      <Modal
        data-testid="export-details-modal"
        id="confirmation-details-modal"
        className="ExportModal"
        onClose={() => setShowModal(false)}
        show={showModal}
        closeOnBlur={false}
        footer={
          <Footer>
            <CancelButton>
              <Button
                data-testid="modal-cancel"
                appearance="secondary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {t(`ecp-endpointsecurity-devicespage.common.exportModal.footer.cancel`, 'Cancel')}
              </Button>
            </CancelButton>
            <DownloadButton>
              <Button
                data-testid="modal-submit"
                onClick={() => {
                  setShowModal(false);
                  onExport();
                }}
                disabled={selectedChoice.length == 0}
              >
                {t(`ecp-endpointsecurity-devicespage.common.exportModal.footer.export`, 'Export')}
              </Button>
            </DownloadButton>
          </Footer>
        }
      >
        <Title>
          {t(`ecp-endpointsecurity-devicespage.common.exportModal.footer.export`, 'Export') +
            ' ' +
            modalTitle}
        </Title>
        <Subtitle>
          {t(
            `ecp-endpointsecurity-devicespage.common.exportModal.reportSubtitle`,
            'Select a file format you would like to use for your exported report. Once completed, your file will appear in the Downloads menu.'
          )}
        </Subtitle>
        <ExportTypeSelect>
          <Select
            data-testid="export-type"
            options={[
              { value: 1, label: 'PDF' },
              { value: 1, label: 'CSV', disabled: true }
            ]}
            id="select-label"
            clearIcon={false}
            value={fileFormat}
            onChange={({ value: v }) => setFileFormat([v])}
          />
        </ExportTypeSelect>
      </Modal>
    </div>
  );
};
