import React from 'react';
import { RootProvider } from '@jarvis/react-portal-addons';
import resources from '../../assets/locale/index';
import { DeviceDetails } from '../DeviceDetails';
import { setProps } from '../../utils/commonMethods';
import { ThemeProvider } from '@veneer/theme';
import { getSystem } from '../../shared-components/WEXTheme';
import { SemanticsExtended } from '@veneer/semantics';

const Wex = (props) => {
  setProps(props);
  const { isWex, localization, deviceId, demoEnabled} = props;
  const config = props.getConfig();
  const apiPath = props.apiPath;
  const mockNoDevices = props.demoModeOptions?.mockNoDevices;
  const requestOptions = props.requestOptions;

  const navigation = {
    location: {
      pathname: '/device-details/fdgsdf/AQAAAAF242n2kgAAAAHbF5gB/AQAAAAF242n2kgAAAAHbF5gB'
    }
  };
  return (
    <div>
      <RootProvider localization={localization} resources={resources} mockLocalization={true}>
        <ThemeProvider
          {...props.themeProviderProps}
          mode={
            isWex
              ? (props?.configPath?.theme.mode as 'light' | 'dark' | 'contrast')
              : (props.userThemeMode as 'light' | 'dark' | 'contrast')
          }
          customSemantics={
            isWex
              ? getSystem(props?.configPath?.theme.selectedColor)
              : ('default' as unknown as SemanticsExtended)
          }
          density="high"
          shape={isWex ? 'pill' : 'round'}
        >
          <DeviceDetails
            isWex={isWex}
            demoEnabled={demoEnabled}
            apiPath={apiPath}
            mockNoDevices={mockNoDevices}
            requestOptions={requestOptions}
            deviceId={deviceId ?? ''}
            navigation={navigation}
            stack={config.ecpStackId}
            breadCrumbs={'any'}
            locale={'en-US'}
            accessControl={{ checkScopes: () => true }}
          />
        </ThemeProvider>
      </RootProvider>
    </div>
  );
};

export default Wex;
