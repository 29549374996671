// import { OptionInterface } from "@veneer/core/dist/scripts/contextual_menu";
import { TableI18n } from "@veneer/core";
const environmentVariables = {
  local: {
    deviceApiUrl: "http://localhost:3001",
    SSApiUrl: "http://localhost:3001",
    PolicyApiUrl: "http://localhost:3001"
  },
  dev: {
    deviceApiUrl: "https://dev-us1.api.ws-hp.com/devicecache",
    SSApiUrl: "https://dev-us1.api.ws-hp.com/securitymgrfleet",
    PolicyApiUrl: "https://dev-us1.api.ws-hp.com/fleetmgt"
  },
  pie: {
    deviceApiUrl: "https://pie-us1.api.ws-hp.com/devicecache",
    SSApiUrl: "https://pie-us1.api.ws-hp.com/securitymgrfleet",
    PolicyApiUrl: "https://pie-us1.api.ws-hp.com/fleetmgt"
  },
  stage: {
    deviceApiUrl: "https://stage-us1.api.ws-hp.com/devicecache",
    SSApiUrl: "https://stage-us1.api.ws-hp.com/securitymgrfleet",
    PolicyApiUrl: "https://stage-us1.api.ws-hp.com/fleetmgt"
  },
  prod: {
    deviceApiUrl: "https://us1.api.ws-hp.com/devicecache",
    SSApiUrl: "https://us1.api.ws-hp.com/securitymgrfleet",
    PolicyApiUrl: "https://us1.api.ws-hp.com/fleetmgt"
  }
};
export const filterOptions = {
  outputLevel: "minimal",
  sortBy: "connectionState",
  order: "descending",
  offset: 0,
  limit: 25,
  search: "",
  serviceId: "ws-hp.com/smcloud"
};
export const defaultPageSizeOptions = [
  { value: 25 },
  { value: 50 },
  { value: 75 },
  { value: 100 }
];
const riskIndicationColors = {
  passed: "#57AEA4",
  low: "#FFCE33",
  medium: "#F35D3E",
  high: "#D41E4F",
  notAssessed: "#8400BE",
  unknown: "#ADADAD"
};
const assessmentStatusColors = {
  highRisk: "#be1313",
  mediumRisk: "#d06702",
  lowRisk: "#f1c029",
  notAssessed: "#8400be",
  passed: "#309F2F",
  unknown: "#adadad"
};
const deviceStatusColors = { online: "#309F2F", offline: "#ADADAD" };
const policyComplianceColors = {
  compliant: "#309F2F",
  noncompliant: "#D41E4F",
  notAssessed: "#adadad"
};
export const reportType = {
  assessment: "assessment",
  remediation: "remediation"
};
export function getEnvConfig(env) {
  switch (env) {
    case "local":
      return environmentVariables.local;
    case 0:
      return environmentVariables.dev;
    case 1:
      return environmentVariables.pie;
    case 2:
      return environmentVariables.stage;
    case 3:
      return environmentVariables.prod;
    case 4:
      return environmentVariables.local;
    default:
      return environmentVariables.pie;
  }
}
export const showSubSetting = {
  "ews-password": true,
  "snmp-v1-v2": true,
  "snmp-v3": true,
  "pjl-password": false,
  "remote-cfg-password": true,
  "svc-access-code": false,
  "fs-password": false,
  slp: false,
  "ipv4-multicast": false,
  llmnr: false,
  "ws-discovery": false,
  bonjour: false,
  "tcpip-print": false,
  airprint: false,
  "lpd-lpr": false,
  ipp: false,
  ipps: false,
  "ws-print": false,
  "file-erase": false,
  "retain-jobs": true,
  "ctrl-panel-lock": false,
  "ctrl-panel-timeout": false,
  "dc-ports": false,
  "host-usb-pnp": true,
  "check-latest": true,
  "secure-boot-presence": false,
  "intrusion-detect-presence": false,
  "whitelist-presence": false,
  "pjl-command": false,
  "ps-security": false,
  "fax-receive": true,
  "fs-access-protocol": true,
  "disk-encryption": false,
  "verify-certificate": false,
  "wins-port": false,
  "wins-registration": false,
  "csrf-prevention": false,
  "web-encryption": true,
  "https-redirect": false,
  "ews-access": false,
  "info-tab": true,
  "remote-fw-update": false,
  "auto-fw-update": false,
  "fw-downgrade": false,
  "pin-protection": true,
  wifi: true,
  fwu: true
};
export const retainJobTimes = [30, 60, 240, 1440, 10080, 40320, 0];
export function getRiskIndicationColor(risk) {
  switch (risk) {
    case "passed":
      return riskIndicationColors.passed;
    case "low":
    case "lowRisk":
      return riskIndicationColors.low;
    case "medium":
    case "mediumRisk":
      return riskIndicationColors.medium;
    case "high":
    case "highRisk":
      return riskIndicationColors.high;
    case "notAssessed":
      return riskIndicationColors.notAssessed;
    default:
      return riskIndicationColors.unknown;
  }
}
export function getDeviceStatusColor(status) {
  switch (status) {
    case "online":
      return deviceStatusColors.online;
    case "offline":
      return deviceStatusColors.offline;
    default:
      return deviceStatusColors.offline;
  }
}

export function getAdvancedStatusColor(status) {
  switch (status) {
    case true:
      return deviceStatusColors.online;
    case false:
      return deviceStatusColors.offline;
    default:
      return deviceStatusColors.offline;
  }
}

export function getAssessmentStatusColor(status) {
  switch (status) {
    case "highRisk":
      return assessmentStatusColors.highRisk;
    case "mediumRisk":
      return assessmentStatusColors.mediumRisk;
    case "lowRisk":
      return assessmentStatusColors.lowRisk;
    case "notAssessed":
      return assessmentStatusColors.notAssessed;
    case "passed":
      return assessmentStatusColors.passed;
    case "unknown":
      return assessmentStatusColors.unknown;
    default:
      return assessmentStatusColors.unknown;
  }
}

export const getCompliantStatusColor = status => {
  switch (status) {
    case "assessFailed":
      return policyComplianceColors.compliant;
    case "assessSucceeded":
      return policyComplianceColors.noncompliant;
    case "notAssessed":
      return policyComplianceColors.notAssessed;
    default:
      return policyComplianceColors.notAssessed;
  }
};
export const Tablei18nText = (t): TableI18n => {
  return {
    actionButton: "",
    cancelButton: "",
    clearAllFilters: "",
    columnResizeTooltip: "",
    columnOptions: "",
    deselectAllItems: "",
    downButton: "",
    itemSelected: "",
    itemsSelected: "",
    noItems: t(
      "ecp-endpointsecurity-devicespage.report.placeholder.noData",
      "No Data"
    ),
    resetToDefault: "",
    saveButton: "",
    selectAllItems: "",
    selectAllPageItems: "",
    sortedAscending: "",
    sortedDescending: "",
    upButton: "",
    sortByAscending: "",
    sortByAscendingActive: "",
    sortByDescending: "",
    sortByDescendingActive: ""
  };
};

export const Colors = {
  riskIndicationColors,
  gray1: "#f1f1f1",
  gray2: "#ebebeb",
  gray3: "#dbdbdb",
  gray4: "#adadad",
  gray5: "#737373",
  gray6: "#585858",
  purple6: "#8400BE",
  white: "#ffffff",
  black: "#000000",
  HpBlue: "#0278ab"
};
