import React from "react";
import PropTypes from "prop-types";
import { RootProvider } from "@jarvis/react-portal-addons";
import resources from "../../../assets/locale/index";
import { DeviceDetails } from "../../DeviceDetails";
import { setProps } from "../../../utils/commonMethods";
import "../../../../src/styles/global.scss";

const SubMfeECPDevices = props => {
  const { localization, breadCrumbs } = props;

  setProps(props);

  return (
    <div className="SubMfeECPDevices">
      <RootProvider localization={localization} resources={resources}>
      <DeviceDetails
        breadCrumbs={breadCrumbs}
        navigation={props.navigation}
        stack={props.stack}
        setDeviceName={props.setDeviceName}
        accessControl={props.accessControl}
        locale={localization?.locale}
      />
      </RootProvider>
    </div>
  );
};

SubMfeECPDevices.defaultProps = {
  shell: {},
  stack: null
};

SubMfeECPDevices.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number
};

export default SubMfeECPDevices;
